import React from "react";
import PropTypes from "prop-types";

import Image from "../components/image";

function SubTitle({ subtitle }) {
    if (!subtitle) {
        return null;
    }
    return <p className="paragraph text-gray-700 text-lg">{subtitle}</p>;
}

function Bigtitle({ props, size }) {
    return (
        <section className="max-w-6xl mx-auto py-12 md:py-12">
            <div className="flex flex-col w-full text-center">
                <div className="max-w-2xl mx-auto mb-8">
                    <h2
                        className={`${size} font-display font-light leading-tight mb-6`}
                    >
                        {props.title}
                    </h2>

                    <SubTitle subtitle={props.subtitle} />
                </div>
                <Image filename={props.image} alt="" />
            </div>
        </section>
    );
}

Bigtitle.defaultProps = {
    size: `text-5xl`,
};

Bigtitle.propTypes = {
    props: PropTypes.array,
    title: PropTypes.text,
    subtitle: PropTypes.text,
    image: PropTypes.text,
    size: PropTypes.text,
};

SubTitle.propTypes = { subtitle: PropTypes.text };

export default Bigtitle;
