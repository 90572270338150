import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";
import FeatureList from "../components/feature-list";
import SideFeature from "../components/side-feature";
import Bigtitle from "../components/bigtitle";
// import HeroImg from "../images/hero-1.png";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

function Fulfillment({ data }) {
    let d = data.contentfulPageFulfillment;

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <Hero
                props={{
                    label: d.label,
                    title: d.title,
                    desc: renderRichText(d.subtitle),
                    style: {
                        // color: `#5D5C4B`,
                        // backgroundColor: `#F6F4C7`,
                        // backgroundImage: `linear-gradient(0, rgba(0,0,0, .2) 0%, rgba(0,0,0, .2) 100%), url(${HeroImg})`,
                        backgroundRepeat: `no-repeat`,
                        backgroundPosition: `20% 100%`,
                        // backgroundSize: `cover`,
                    },
                }}
            />

            <SideFeature
                props={{
                    title: d.sideFeatures.title,
                    image: d.sideFeatures.image,
                    features: d.sideFeatures.features,
                }}
            />

            <Bigtitle
                props={{
                    title: d.bigTitle.title,
                    image: d.bigTitle.image,
                }}
                size="text-3xl"
            />

            <section className="max-w-6xl mx-auto py-10">
                <div className="flex flex-wrap w-full">
                    {d.features.map((item) => (
                        <FeatureList key={item.title} props={item} />
                    ))}
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

Fulfillment.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageFulfillment {
            seo {
                title
                description
                keywords
            }
            label
            title
            subtitle {
                raw
            }
            features {
                title
                icon
                description
            }
            sideFeatures {
                title
                image
                features {
                    description
                    icon
                    title
                }
            }
            bigTitle {
                title
                image
            }
        }
    }
`;

export default Fulfillment;
